<template>
    <div class="flex-grow-1">
        <div class="bg-gray-100 px-proportal py-3">
            <div class="row align-items-center gy-2">
                <div class="col-xl-9">
                    <form @submit="onSubmit" class="row gy-2">
                        <div class="col-md-11">
                            <div class="row gy-2">
                                <div class="col-md-4">
                                    <TextField
                                        class="mb-0"
                                        name="clientName"
                                        type="text"
                                        :label="`${customerTerminologyName} name`"
                                        form-floating>
                                        <template #icon-start>
                                            <Icon symbol="user"></Icon>
                                        </template>
                                    </TextField>
                                </div>
                                <div class="col-md-4">
                                    <DatePickerField
                                        class="mb-0"
                                        name="dateRange"
                                        label="Date Range"
                                        :date-picker-config="{
                                            format: 'dd MMM yyyy',
                                            autoApply: true,
                                            range: true,
                                            multiCalendars: true,
                                            ignoreTimeValidation: false,
                                            enableTimePicker: false,
                                        }"
                                        form-floating>
                                        <template #icon-start>
                                            <Icon symbol="calendar"></Icon>
                                        </template>
                                    </DatePickerField>
                                </div>
                                <div class="col-md-4">
                                    <AutocompleteField
                                        class="mb-0"
                                        name="skinMeasurementValueIds"
                                        label="Skin Measurement Values"
                                        form-floating
                                        :items="skinMeasurementValues"
                                        item-text="name"
                                        item-value="key"
                                        multiple
                                        chips
                                        :chip-limit="1"
                                    ></AutocompleteField>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 d-grid d-md-flex">
                            <button
                                type="submit"
                                class="btn btn-lg btn-primary px-3 gap-2 filter-cta"
                                title="Search consultations">
                                <Icon symbol="search" class="text-white flex-shrink-0" size="m"></Icon>
                                <span class="d-md-none">Search</span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-xl-3 d-grid d-md-flex justify-content-md-end">
                    <router-link :to="{name: 'customer-details'}" class="btn btn-dark px-4 py-2 fw-bold hstack gap-2">
                        <Icon symbol="paper" size="m"></Icon>
                        <span>New Consultation</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="px-proportal py-3">
            <div class="nav nav-pills flex-nowrap mb-5 overflow-x-auto">
                <div v-for="item in consultationStatuses" class="nav-item">
                    <button
                        @click="setFieldValue('consultationStatusId', item.key)"
                        class="nav-link fw-bold"
                        :class="{ 'active': item.key === values.consultationStatusId }"
                        type="button">
                        {{ item.name }}
                    </button>
                </div>
            </div>

            <GridPlaceholder
                v-if="isBusy"
                grid-config="1x4"
                grid-config-lg="3x4"
                grid-item-ratio="21x6"
            ></GridPlaceholder>

            <div v-else-if="paginatedResults && paginatedResults.count > 0">
                <div class="grid consultation-grid">
                    <div v-for="item in paginatedResults.items" :key="item.consultation.id" class="card g-col-12 g-col-md-6 g-col-xl-4">
                        <div class="card-body d-flex flex-column">
                            <div class="row flex-grow-1">
                                <div class="col-8 mb-2">
                                    <button @click="onEditConsultation(item)" class="btn-link fw-bolder heading-s link-dark btn p-0 text-decoration-none text-start heading-m fw-bold lh-1">
                                        {{ item.client.name }}
                                    </button>
                                </div>
                                <div class="col-4 d-flex justify-content-end flex-wrap align-items-start subheading">
                                    <div
                                        class="badge text-dark rounded-pill"
                                        :style="{ backgroundColor: `#${getConsultationStatusByKey(item.consultation.consultationStatusId)?.colorTheme?.color}`}">
                                        {{ item.status.name }}
                                    </div>
                                    <div v-if="item.consultationMethod" class="badge text-bg-info rounded-pill">{{ item.consultationMethod.name }}</div>
                                </div>
                            </div>
                            
                            <div class="hstack gap-3">
                                <div class="hstack gap-2 text-muted">
                                    <Icon symbol="calendar"></Icon>
                                    <p class="mb-0 caption">Created on {{ item.date }}</p>
                                </div>
                                <div v-if="item.clonedConsultationId" class="hstack gap-2 text-muted">
                                    <Icon symbol="exchange"></Icon>
                                    <p class="mb-0 caption">Follow-up</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-white hstack gap-1 justify-content-end">
                            <DownloadReportCta
                                :consultation-id="item.consultation.id"
                                :spinner-attr="{
                                    class: 'spinner-black',
                                    width: 20,
                                    height: 20
                                }"
                                class="btn btn-sm btn-overlay-dark fw-bold hstack gap-2 align-self-auto caption">
                                <template #icon>
                                    <Icon symbol="download-2"></Icon>
                                </template>
                                <span>Download</span>
                            </DownloadReportCta>
                            <button
                                @click="onEditConsultation(item)"
                                class="btn btn-sm btn-overlay-dark fw-bold hstack gap-2 align-self-auto caption"
                                type="button">
                                <Icon symbol="edit"></Icon>
                                Edit
                            </button>
                            <button @click="onDeleteConsultation(item)" class="btn btn-sm btn-overlay-danger gap-2 fw-bold caption">
                                <Icon symbol="trash-2"></Icon>
                                <span class="visually-hidden">Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="py-5">
                <div v-if="hasFilters">
                    <h2 class="display-xl text-primary mb-5">Sorry, we couldn't find consultations matching your criteria.</h2>
                </div>
                <div v-else class="py-5">
                    <h2 class="display-xl text-primary mb-5">There are no <br>consultations yet...</h2>
                    <router-link
                        :to=" { name: 'customer-details' }"
                        class="btn btn-lg btn-dark px-4 py-2 hstack gap-3">
                        <Icon symbol="paper" size="m"></Icon>
                        <span>New Consultation</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <ModalContinueConsultation
        ref="continueConsultationModalRef"
        v-if="consultationToEdit"
        :current-consultation="consultationToEdit"
    ></ModalContinueConsultation>

    <ModalDeleteConsultation
        ref="deleteConsultationModalRef"
        v-if="consultationToDelete"
        :consultation-id="consultationToDelete.consultation.id"
    ></ModalDeleteConsultation>

    <Teleport to="#stickyBottom">
        <div v-if="paginatedResults && paginatedResults.items.length > 0" class="px-proportal bg-white border-top py-2">
            <Pagination
                :disabled="isBusy"
                :page-number="paginatedResults.currentPageNumber"
                :total-pages="paginatedResults.totalPages"
                :pages-per-view="3"
                :pages-per-view-md="10"
                @page-change="onPageChange"
                class="d-flex justify-content-center"
            ></Pagination>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
import { AutocompleteField, DatePickerField, GridPlaceholder, Icon, Pagination, TextField } from '@/modules/core/components';
import { usePortalConsultationsStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { nextTick, ref, watch } from 'vue';
import { ConsultationsFilterFormFields } from './types';
import { DownloadReportCta } from '@/modules/proportal/components';
import dayjs from 'dayjs';
import ModalContinueConsultation from './modal-continue-consultation.vue';
import { ConsultationRecord } from '../../types';
import debounce from 'lodash/debounce';
import ModalDeleteConsultation from './modal-delete-consultation.vue';

const continueConsultationModalRef = ref<InstanceType<typeof ModalContinueConsultation>>();
const deleteConsultationModalRef = ref<InstanceType<typeof ModalDeleteConsultation>>();
const consultationToEdit = ref<ConsultationRecord>();
const consultationToDelete = ref<ConsultationRecord>();

const isBusy = ref(true);
const consultationsStore = usePortalConsultationsStore();

const { 
    filters,
    paginatedResults,
    consultationStatuses,
    hasFilters,
    customerTerminologyName,
    getConsultationStatusByKey,
    skinMeasurementValues
} = storeToRefs(consultationsStore);

const toIsoDate = (date: Date|string|null) => {
    if (!date) return '';
    return dayjs(date).format('YYYY-MM-DD')
}

const updateStoreFilters = async (values: ConsultationsFilterFormFields) => {
    const { dateRange, ...restValues } = values;
    consultationsStore.$patch({
        filters: {
            ...filters.value,
            ...restValues,
            fromDateTime: dateRange && dateRange.length && dateRange[0] ? toIsoDate(dateRange[0]) : '',
            toDateTime: dateRange && dateRange.length && dateRange[1] ? toIsoDate(dateRange[1]) : '',
        }
    })
}

const {
    values,
    handleSubmit,
    setFieldValue
} = useForm<ConsultationsFilterFormFields>({
    initialValues: {
        ...filters.value,
        dateRange: [filters.value.fromDateTime, filters.value.toDateTime],
    },
});

const onSubmit = handleSubmit(
    () => doSearch()
)

const doSearch = async () => {
    isBusy.value = true;
    await consultationsStore.getConsultations()
    isBusy.value = false;
}

const onPageChange = async (pageNumber: number) => {
    isBusy.value = true;
    filters.value.pageNumber = pageNumber;
    await consultationsStore.getConsultations()
    isBusy.value = false;
}

watch(values, debounce(() => {
    updateStoreFilters(values);
    doSearch()
}, 300))

const onEditConsultation = (consultation: ConsultationRecord) => {
    consultationToEdit.value = consultation;
    nextTick(() => continueConsultationModalRef.value?.show())
}

const onDeleteConsultation = (consultation: ConsultationRecord) => {
    consultationToDelete.value = consultation;
    nextTick(() => deleteConsultationModalRef.value?.show())
}

doSearch();

</script>

<style lang="scss" scoped>
.filter-cta {
    @include media-breakpoint-up (md) {
        width: 100%;
    }
    @include media-breakpoint-up (xl) {
        width: auto;
    }
}

.consultation-grid {
    grid-auto-rows: 1fr;
}
</style>
