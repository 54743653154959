<template>
    <GenericModal
        ref="modalRef"
        modal-title="Edit consultation"
        @hidden="onHidden"
        size="modal-lg"
        scrollable>
        <template #activator="scope">
            <slot name="activator" v-bind="scope"></slot>
        </template>
        <Tabs class="px-4 modal-continue-consultation-tabs border-top border-bottom pt-3 bg-light bg-opacity-50">
            <TabPane :id="`continue-consultation-${consultation.id}`" title="Continue consultation">
                <div class="py-4 px-4">
                    <p class="heading-s mb-4">Do you want to continue where you left?</p>
                    <div class="d-flex flex-column-reverse flex-md-row gap-2 gap-md-3 justify-content-end">
                        <button
                            @click="onStartFromBeginning"
                            class="btn btn-overlay-primary fw-bold"
                            type="button">
                            Start from Beginning
                        </button>
                        <button
                            @click="onContinueFromLastTime"
                            class="btn btn-primary fw-bold"
                            type="button"
                            :disabled="!consultation.lastVisitedUrl">
                            Continue from Last Time
                        </button>
                    </div>
                </div>
            </TabPane>
            <TabPane v-if="getConsultationStatusByKey(consultation.consultationStatusId)?.name === 'Completed'" :id="`followup-forms-${consultation.id}`" title="Follow Up">
                <form class="pt-3 px-4">
                    <SelectionButtonsField
                        name="appointmentTypeId"
                        label="Select the appointment type"
                        :items="followUpAppointmentTypes"
                        item-text="name"
                        item-value="key"
                        :column-count-md="2"
                        mandatory
                        class="slim"
                    ></SelectionButtonsField>
                </form>
                <FollowUpForm 
                    v-if="values.appointmentTypeId" 
                    :appointment-type="getAppointmentTypeByKey(values.appointmentTypeId)!"
                    :current-consultation="currentConsultation"
                    class="px-4 pb-4"
                ></FollowUpForm>
            </TabPane>
        </Tabs>
    </GenericModal>
</template>

<script lang="ts" setup>
import { GenericModal, Tabs, TabPane, SelectionButtonsField } from '@/modules/core/components';
import { PropType, ref } from 'vue';
import { router } from '../../router';
import { ConsultationRecord } from '@/modules/proportal/types';
import { useCommonDataStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { FollowUpForm } from './step-follow-up'

const props = defineProps({
    currentConsultation: { type: Object as PropType<ConsultationRecord>, required: true }
})

const { consultation } = props.currentConsultation;

const commonDataStore = useCommonDataStore();
const { getConsultationStatusByKey, appointmentTypes, getAppointmentTypeByKey } = storeToRefs(commonDataStore);

const followUpAppointmentTypes = appointmentTypes.value.filter(x => x.allowInConsultationFlow);

const { values } = useForm({
    initialValues: {
        appointmentTypeId: undefined
    }
});

const modalRef = ref<InstanceType<typeof GenericModal>>();
let hiddenCallbackToRun: () => void;

const onStartFromBeginning = () => {
    hiddenCallbackToRun = () => router.push({
        name: 'process',
        params: {
            consultationId: consultation.id
        }
    })
    modalRef.value?.hide()
}

const onContinueFromLastTime = () => {
    hiddenCallbackToRun = () => {
        // If the lastVisitedURL is wrong (doesn't start with /c/:consultationId)
        // Redirect them to process page
        if(!consultation.lastVisitedUrl.startsWith(`/c/${consultation.id}`)) {
            return router.push({
                name: 'process',
                params: {
                    consultationId: consultation.id
                }
            })
        }
        // Else, return them normally
        return router.push(
            { 
                path: consultation.lastVisitedUrl
            }
        )
    };
    modalRef.value?.hide()
}

const onHidden = () => {
    hiddenCallbackToRun && hiddenCallbackToRun()
}

defineExpose({
    show: () => modalRef.value?.show()
})
</script>

<style lang="scss">
.modal-continue-consultation-tabs {
    &:after {
        display: none;
    }
    .nav-link {
        padding: 0 0 0.8rem 0;
        font-size: 1rem;
        &:after {
            bottom: 1px;
            border-top: 2px solid transparent;
        }
        &.active {
            --bs-nav-tabs-link-active-bg: transparent;
            &:after {
                bottom: 1px;
                border-top: 2px solid;
            }
        }
        &:hover, &:focus {
            &:after {

                border-top: 2px solid;
            }
        }
    }
}
</style>
