<template>
<div class="grid-placeholder" :style="styleToken">
    <div class="grid-item placeholder-glow"
        v-for="(item, index) in count" 
        :key="item">
        <div class="placeholder ratio bg-primary rounded-2"
            :class="{
                ['ratio-' + gridItemRatio]: true
            }"
            :style="{
                'animation-delay': (index * 0.1) + 's'
            }"
        ></div>
    </div>
</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core';

const props = defineProps({
    gridConfig: {
        type: String,
        default: '2x3'
    },
    gridConfigLg: {
        type: String,
        default: '3x3',
    },
    gridItemRatio: {
        type: String,
        default: '1x1'
    },
})

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const lgAndGreater = breakpoints.greater('lg');

const gridSettings = computed((): { columns: number, rows: number } | undefined => {
    const settings = props.gridConfig.split('x')
    const settingsLg = props.gridConfigLg.split('x');

    if (settings.length < 2) return;
    return {
        columns: parseInt(!lgAndGreater.value ? settings[0] : settingsLg[0]) || 0,
        rows: parseInt(!lgAndGreater.value ? settings[1] : settingsLg[1]) || 0
    }
})

const count = computed((): number | undefined => {
    const settings = props.gridConfig
        .split('x')
        .map(x => parseInt(x));
    const settingsLg = props.gridConfigLg
        .split('x')
        .map(x => parseInt(x));

    if (settings.length === 0) return;
    if (settingsLg.length === 0) return;

    return !lgAndGreater.value 
        ? settings[0] * (settings[1] || 1)
        : settingsLg[0] * (settingsLg[1] || 1)
})

const styleToken = computed(() => `--column-count: ${gridSettings.value?.columns}; --row-count: ${gridSettings.value?.rows};`)
</script>

<style lang="scss">
    .grid-placeholder {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(var(--column-count, 2), 1fr);

        @include media-breakpoint-up (md) {
            grid-template-columns: repeat(var(--column-count, 3), 1fr);
        }
    }
</style>