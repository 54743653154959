<template>
    <GenericModal ref="modalRef" modal-title="Delete Consultation" v-slot="{ hide }">
        <div class="px-4"><p class="text-muted">Confirm to delete this consultation?</p></div>
        <div class="pb-4 px-4 hstack gap-2 justify-content-end">
            <button
                @click="hide"
                class="btn btn-overlay-dark"
                type="button"
                :disabled="isBusy">
                Cancel
            </button>
            <ButtonWithSpinner
                @click="onDelete(hide)"
                class="btn btn-dark"
                type="button"
                :is-busy="isBusy"
                :disabled="isBusy">
                Delete
            </ButtonWithSpinner>
        </div>
    </GenericModal>
</template>

<script setup lang="ts">
import { ButtonWithSpinner, GenericModal } from '@/modules/core/components';
import { ref } from 'vue';
import { usePortalConsultationsStore } from '../../store';

interface Props {
    consultationId: string;
}

const props = defineProps<Props>()

const consultationsStore = usePortalConsultationsStore();

const isBusy = ref(false);

const modalRef = ref<InstanceType<typeof GenericModal>>();

const onDelete = async (hideModal?: () => void) => {
    isBusy.value = true;
    await consultationsStore.deleteConsultationById(props.consultationId);
    hideModal && hideModal();
    consultationsStore.getConsultations(1);
    isBusy.value = false;
}

defineExpose({
    show: () => modalRef.value?.show()
})
</script>

<style scoped>

</style>